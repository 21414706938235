<template>
  <div class="del mywidth ">
    <div class="box-bg">
     <el-breadcrumb separator-class="el-icon-arrow-right" class="pagetop">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
       <el-breadcrumb-item v-if="id!=='buss'" :to="{ path: '/notice' }">{{con.categoryname}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{con.title}}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="con btw-l">
      <del-l :con="con" :file="file"></del-l>
      <!-- <del-r></del-r> -->
    </div>
    </div>
  </div>
</template>

<script>
import { noticedel, file } from "@/api/notice.js";
import DelL from "./com/delL";
// import DelR from "@components/my-del-r";
export default {
  name: "NoticeDel",
  components: {
    DelL,
    // DelR,
  },
  data() {
    return {
      id: "",
      con: {},
      file:{
        con:[],
        showfile:true
      },

    };
  },
  watch:{
     '$route.query.id'(val){
          this.getId(val)
     }
  },
  mounted() {
    this.getId(this.$route.query.id)
  },
  methods: {
    getId(id){
      this.id = id
      if(id=="buss"){
        this.con = JSON.parse(sessionStorage.getItem('busscontent'))
        this.file.showfile=false
      }else{
        this.getnoticedel();
      }

    },
    getnoticedel() {
      let params = {
        id: this.id,
      };
      noticedel(params).then((data) => {
        this.con = data.tdata;
        if(data.tdata.fileguid){
           this.getfile(data.tdata.fileguid)
        }
        this.file.showfile = data.tdata.fileguid?true:false
      });
    },
    getfile(val){
      let params = {
        fileguid: val,
      };
       file(params).then((data) => {
        this.file.con = data.tdata;
      });
    }
  },
};
</script>

<style lang="less" scoped>
.del {
  margin: 20px auto;
}
::v-deep .el-page-header__content {
  font-size: 15px;
}
.con {
  padding: 10px 0;
  .conl {
    width: 835px;
  }
}
</style>
