import request from '@/utils/request'
// 新闻类别
export function category(params) {
  return request({
    url: 'web/home/subcategory.json',
    method: 'get',
    params
  })
}
// 新闻列表
export function list(params) {
  return request({
    url: 'web/home/newsAndAnnouncement.json',
    method: 'get',
    params
  })
}
// 新闻详情
export function noticedel(params) {
  return request({
    url: 'web/home/footContent.json',
    method: 'get',
    params
  })
}
// 获取详情附件
export function file(params) {
  return request({
    url: 'standalonfile/operate/getlist.json',
    method: 'get',
    params
  })
}