<template>
  <div class="delfile">
      附件：
      <el-table :data="fileList" border style="width: 100%;margin:20px auto;">
          <el-table-column prop="filename" label="文件名称">
          </el-table-column>
          <el-table-column prop="address" label="操作">
              <template slot-scope="scope">
                  <el-button @click="dwonload(scope.row.rowguid)">下载</el-button>
              </template>
          </el-table-column>
      </el-table>
  </div>
</template>

<script>
export default {
  name: "delfile",
  props:['fileList'],
  data() {
    return {
     
    };
  },
  methods: {
    serherf(val) {
      console.log(val,'serherf');
    },
    dwonload(id){
        window.location.href="/standalonfile/download/common.do?rowguid="+id
    }
  },
};
</script>

<style lang="less" scoped>
.delfile{
 
}
</style>