<template>
  <div class="del-l">
    <div class="delcon  " v-html="con.infocontent"></div>
    <del-file v-if="file.showfile" :fileList="file.con"></del-file>
  </div>
</template>

<script>
import DelFile from "@components/my-delfile";
export default {
  name: "conr",
  props:['con','file'],
  components: {
    DelFile
  },
  data() {
    return {

    };
  },
  methods: {
  
  },
};
</script>

<style  lang="less"  scoped>


</style>